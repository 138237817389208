import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";

import PrivateWrapper from "../../Layouts/PrivateWrapper";
import telegramLogo from "../../Assests/Images/telegram.png";
import TokenHandler from "../../Utils/TokenHandler";
import AuthService from "../../Services/Auth/AuthService";

import "./Styles.scss";
import { Spin } from "antd";
import useMatchMedia from "../../Hooks/useMatchMedia";
import { TABLET_MAX_WIDTH_QUERY } from "../../Constants/Values";

const { REACT_APP_TELEGRAM_BOT_URL } = (window as any).__env__;

const Onboarding = () => {
  const { t } = useTranslation();
  const externalReference = TokenHandler?.getExternalRef();
  const [loading, setLoading] = React.useState<boolean>(false);
  const { match: isMobileDevice } = useMatchMedia(TABLET_MAX_WIDTH_QUERY);

  const checkStatusHandler = () => {
    setLoading(true);
    AuthService.refreshToken()
      .then((res) => {
        window.location.reload();
        setLoading(false);
      })
      .catch((e) => {
        setLoading(false);
        console.log(e);
      });
  };

  useEffect(() => {
    if (!externalReference) return;

    checkStatusHandler();
  }, []);

  const onboardingHandler = () => {
    return (
      <div className="xl:row">
        <div className={`col-md-10 ${isMobileDevice ? "m-auto" : "mt-16"}`}>
          <div
            className={`xl:m-auto xl:mt-4 xl:py-1 py-20 px-4 ${
              isMobileDevice ? "bg-white" : "bg-transparent col-md-7"
            } rounded-t-[20px]`}
          >
            <div className="px-8 flex items-center flex-col justify-start">
              <div className="bg-lemon-green w-14 h-14 flex items-center justify-center rounded-full">
                <i className="bi bi-check text-5xl text-white"></i>
              </div>

              <span className="font-sans text-sm font-normal text-gray-darker mb-2 mt-1">
                {t("telegram-bot-step-success")}
              </span>

              <span className="font-sans text-sm font-normal text-gray-darker">
                {t("telegram-bot-step-click-link")}
              </span>

              <button
                className="bg-blue text-white font-sans text-base font-normal rounded-xl px-8 py-3 rounded-full mt-2"
                onClick={() => telegramLinkHandler()}
              >
                {t("telegram-bot-step-link-button")}
              </button>
            </div>

            <div className="p-2 text-center">
              <span className="font-sans text-base font-normal text-gray-darkest">
                {t("telegram-bot-step-click-refresh-link")}
              </span>

              <br />
              <button
                className="bg-blue text-white font-sans text-sm font-semibold rounded-xl px-6 py-3 m-2 rounded-full w-40"
                type="button"
                onClick={() => checkStatusHandler()}
              >
                {t("telegram-bot-step-refresh-button")}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  };

  useEffect(() => {
    if (!externalReference) return;

    checkStatusHandler();
  }, []);

  const telegramLinkHandler = () => {
    window.open(REACT_APP_TELEGRAM_BOT_URL, "_blank");
  };

  return (
    <PrivateWrapper>
      {!loading ? (
        onboardingHandler()
      ) : (
        <div className="spinner-container">
          <Spin size="large" />
        </div>
      )}
    </PrivateWrapper>
  );
};

export default Onboarding;
