import React from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";
import storageHandler from "../../Utils/StorageHandler";

interface LogoutLinkProps {
  className?: string;
  showOnlyIfNoSession?: boolean;
}

const LogoutLink: React.FC<LogoutLinkProps> = ({
  className = "text-base font-sans font-medium text-blue-dark underline",
  showOnlyIfNoSession = true,
}) => {
  const { t } = useTranslation();

  const handleLogout = () => {
    storageHandler.clear();
    window.location.href = "/";
  };

  const shouldShow =
    !showOnlyIfNoSession ||
    !new URLSearchParams(window.location.search).get("session");

  if (!shouldShow) return null;

  return (
    <Link to="" className={className} onClick={handleLogout}>
      {t("private-wrapper-log-out")}
    </Link>
  );
};

export default LogoutLink;
