import React, { useEffect } from "react";
import mobileChat from "../../../Assests/Images/mobile-chat.webp";
import "react-sliding-pane/dist/react-sliding-pane.css";
import "./styles.scss";
import Registration from "../../../Components/Registration/Registration";
import useMatchMedia from "../../../Hooks/useMatchMedia";
import { TABLET_MAX_WIDTH_QUERY } from "../../../Constants/Values";

type Props = {
  bannerData: any;
};

const preloadedChat = mobileChat;

const preloadImage = (url: string) => (document.createElement("img").src = url);
const preloadBulbasaur = () => preloadImage(preloadedChat);

const Banner = ({ bannerData }: Props) => {
  const { match: isMobileDevice } = useMatchMedia(TABLET_MAX_WIDTH_QUERY);

  useEffect(() => {
    preloadBulbasaur();
  }, []);

  return (
    <div className="py-24 lg:py-28 lg:mt-7 container">
      <div className="xl:flex xl:justify-end">
        <div className="xl:w-1/2">
          <p className="text-lg xl:text-5xl font-sans font-thin text-black mb-2 drop-shadow-4xl">
            {bannerData?.WelcomeTitle || ""}
          </p>
          <span className="text-4xl xl:text-7xl font-sans font-extrabold text-blue-dark drop-shadow-lg">
            {bannerData?.FreeCredits || ""}
          </span>

          {!isMobileDevice && <Registration />}
        </div>
      </div>

      <div className="banner-bottom-container xl:bg-gray xl:border-1 xl:border-gray xl:rounded-4xl xl:p-8">
        <div className="banner-bottom-left">
          <img
            src={mobileChat}
            alt="mobile chat"
            className="z-10 mb-10"
            loading="eager"
          />
        </div>

        {isMobileDevice && <Registration />}

        <div className="banner-bottom-right xl:pt-12 xl:pb-16">
          <h5 className="text-4xl font-sans font-black text-blue-dark mb-6">
            {bannerData?.Section1Title || ""}
          </h5>
          <h5 className="text-xl font-sans font-thin text-light-black">
            {bannerData?.Section1Content || ""}
          </h5>
        </div>
      </div>
    </div>
  );
};

export default Banner;
