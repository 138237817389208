import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import CookieConsent from "react-cookie-consent";
import { useTranslation } from "react-i18next";

import Header from "../Components/Resources/Header/Header";
import Footer from "../Components/Resources/Footer/Footer";
import LoginRegisterPopup from "../Components/LoginRegisterPopup/LoginRegisterPopup";
import OtpPopup from "../Components/OtpPopup/OtpPopup";
import TermsOfService from "../Pages/Terms";

import { ReactComponent as CookieIcon } from "../Assests/Images/cookie.svg";
import { ReactComponent as WhiteLogo } from "../Assests/Images/logo_white.svg";
import { ReactComponent as HomeSmallIcon } from "../Assests/Icons/home-small.svg";
import { ReactComponent as BallSmallIcon } from "../Assests/Icons/ball-small.svg";
import { ReactComponent as RssSmallIcon } from "../Assests/Icons/rss.svg";

import AuthService from "../Services/Auth/AuthService";

import { ROUTE_CONSTANTS } from "../Routes/RouteConstants";
import { TOAST_BOTTOM_CENTER, TOAST_DURATION } from "../Constants/Values";

import Toast from "../Utils/ToastHandler";

import LoggingInLoader from "../Components/LoggingInLoader/LoggingInLoader";

import "./Styles.scss";
import { gtagEventHandler } from "../Utils/gtagEventHandler";
import { hidePhoneNumber } from "../Utils/hidePhoneNumber";
import OffCanvas from "../Components/Resources/OffCanvas/OffCanvas";
import { LoginPopUpProvider, useLoginPopUp } from "../Context/LoginContext";

type Props = {
  isHomepage?: boolean;
  children: React.ReactNode;
  customTitle?: string;
};
const MainWrapper = ({ children, isHomepage, customTitle }: Props) => {
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const [phone, setPhone] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [otpMethod, setOtpMethod] = useState<string>("");
  const [isOtpPopupOpen, setIsOtpPopupOpen] = useState<boolean>(false);
  const {
    isLoginPopupOpen,
    setIsLoginPopupOpen,
    setIsRegistered,
    isRegistered,
  } = useLoginPopUp();
  const [isTermsOpened, setIsTermsOpened] = useState<boolean>(false);
  const [verifying, setVerifying] = useState<boolean>(false);
  const [isOpen, setIsOpen] = useState(false);

  const phoneVerificationHandler = (phoneNumber: string) => {
    const phonePayload = phoneNumber.replace(/\D/g, "");
    setPhone(phoneNumber);
    setLoading(true);
    AuthService.authorizePhoneNumber(phonePayload)
      .then((res) => {
        setLoading(false);
        setIsLoginPopupOpen(false);

        if (res.Result === "PartialRegistration") {
          navigate(ROUTE_CONSTANTS.TELEGRAM, { state: { phone: phoneNumber } });
        } else {
          if (res.Result === "OTPSentViaTelegram") {
            setOtpMethod("Telegram");
          } else {
            setOtpMethod("Sms");
          }

          setIsOtpPopupOpen(true);

          Toast.success(
            t("otp-sent-" + res.Result),
            TOAST_DURATION,
            TOAST_BOTTOM_CENTER,
            "confirm-message-text"
          );
        }

        gtagEventHandler(
          {
            event_category: "Login",
            event_category_description: "Phone Verification Success",
            value: "otp-sent-" + res + " - " + hidePhoneNumber(phoneNumber),
            mobile: phoneNumber,
            event_label: "Phone Verification - OTP Sent",
          },
          "phone_verification_otp_sent"
        );
      })
      .catch((err) => {
        setLoading(false);
        setIsLoginPopupOpen(false);

        const error = err?.response?.data?.Error?.Message || "";

        gtagEventHandler(
          {
            event_category: "Login",
            event_category_description: "Phone Verification Error",
            value: "otp-sent-" + error + " - " + hidePhoneNumber(phoneNumber),
            mobile: phoneNumber,
            event_label: "Phone Verification Failed",
          },
          "phone_verification_failed"
        );

        if (error && error === "PartialRegistrationError") {
          navigate(ROUTE_CONSTANTS.TELEGRAM, { state: { phone: phoneNumber } });
        } else if (error && error === "RegistrationError") {
          Toast.error(
            t("Registration-Error-Login"),
            TOAST_DURATION,
            TOAST_BOTTOM_CENTER
          );
        } else {
          Toast.error(
            error && i18n.exists(error) ? t(error) : t("generic-error"),
            TOAST_DURATION,
            TOAST_BOTTOM_CENTER
          );
        }
      });
  };

  const registerPhoneHandler = (phoneNumber: string, email: string) => {
    const phonePayload = phoneNumber.replace(/\D/g, "");
    setPhone(phoneNumber);
    setLoading(true);
    AuthService.registerPhoneNumber(phonePayload, email)
      .then((res) => {
        setLoading(false);
        setIsLoginPopupOpen(false);

        if (res.Result === "PartialRegistration") {
          navigate(ROUTE_CONSTANTS.TELEGRAM, { state: { phone: phoneNumber } });
        } else {
          if (res.Result === "OTPSentViaTelegram") {
            setOtpMethod("Telegram");
          } else {
            setOtpMethod("Sms");
          }

          setIsOtpPopupOpen(true);

          Toast.success(
            t("otp-sent-" + res.Result),
            TOAST_DURATION,
            TOAST_BOTTOM_CENTER,
            "confirm-message-text"
          );
        }

        gtagEventHandler(
          {
            event_category: "Registration",
            event_category_description: "Phone Verification Success",
            value: "otp-sent-" + res + " - " + hidePhoneNumber(phoneNumber),
            mobile: phoneNumber,
            event_label: "Phone Verification - OTP Sent",
          },
          "phone_verification_otp_sent"
        );
      })
      .catch((err) => {
        const error = err?.response?.data?.Error?.Message || "";

        setLoading(false);
        setIsLoginPopupOpen(false);

        gtagEventHandler(
          {
            event_category: "Registration",
            event_category_description: "Phone Verification Error",
            value: "otp-sent-" + error + " - " + hidePhoneNumber(phoneNumber),
            mobile: phoneNumber,
            event_label: "Phone Verification Failed",
          },
          "phone_verification_failed"
        );

        if (error && error === "PartialRegistrationError") {
          navigate(ROUTE_CONSTANTS.TELEGRAM, { state: { phone: phoneNumber } });
        } else if (error && error === "RegistrationError") {
          Toast.error(
            t("Registration-Error-Register"),
            TOAST_DURATION,
            TOAST_BOTTOM_CENTER
          );
        } else {
          Toast.error(
            error && i18n.exists(error) ? t(error) : t("generic-error"),
            TOAST_DURATION,
            TOAST_BOTTOM_CENTER
          );
        }
      });
  };

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);
    const action = urlParams.get("action");

    if (action === "register") {
      setIsRegistered(true);
      setIsLoginPopupOpen(true);
    } else if (action === "login") {
      setIsRegistered(false);
      setIsLoginPopupOpen(true);
    } else if (action === "terms-of-use") {
      setIsTermsOpened(true);
    } else if (action === "transaction") {
      let session = localStorage.getItem("session");

      if (!session) {
        return;
      }

      session = session.replace('"?', "");
      const decodedSession = window.atob(session);

      const sessionData = decodedSession.split("&");
      const phone = sessionData[0].split("=")[1];
      const otp = sessionData[1].split("=")[1];

      setVerifying(true);
      AuthService.verifyOTP(otp, phone)
        .then((res) => {
          setVerifying(false);
          localStorage.removeItem("session");
          navigate(`${ROUTE_CONSTANTS.TRANSACTION}?tab=profile`);
        })
        .catch(() => {
          setVerifying(false);
          localStorage.removeItem("session");
          navigate(`${ROUTE_CONSTANTS.REGISTER}?action=login`);
          setIsRegistered(false);
          setIsLoginPopupOpen(true);
        });
    }
  }, []);

  return (
    <div
      style={{
        pointerEvents: verifying ? "none" : "auto",
      }}
      // className="container"
    >
      <OffCanvas
        setIsOpen={setIsOpen}
        isOpen={isOpen}
        setTermOpen={setIsTermsOpened}
        setIsLoginPopupOpen={setIsLoginPopupOpen}
      >
        <a href="/" className="text-base font-sans font-medium text-white flex">
          {" "}
          <HomeSmallIcon className="me-3" /> Home Page
        </a>
        <a
          href={ROUTE_CONSTANTS.PRODUCTS}
          className="text-base font-sans font-medium text-white flex"
        >
          {" "}
          <BallSmallIcon className="me-3" /> Products
        </a>
        <a
          href={ROUTE_CONSTANTS.BLOG}
          className="text-base font-sans font-medium text-white flex"
        >
          <RssSmallIcon className="me-3" /> Blog
        </a>
      </OffCanvas>

      <Header
        isHomepage={isHomepage}
        customTitle={customTitle}
        setLoginPopupOpen={setIsLoginPopupOpen}
        setIsOffCanvas={setIsOpen}
      />

      {isLoginPopupOpen && (
        <LoginRegisterPopup
          isRegistered={isRegistered}
          setIsRegistered={setIsRegistered}
          loading={loading}
          registerPhoneHandler={registerPhoneHandler}
          phoneVerificationHandler={phoneVerificationHandler}
          isOpen={isLoginPopupOpen}
          setIsOpen={() => setIsLoginPopupOpen(false)}
        />
      )}

      {isOtpPopupOpen && (
        <OtpPopup
          isOpen={isOtpPopupOpen}
          setIsOpen={setIsOtpPopupOpen}
          loading={loading}
          nextPageHandler={() => {}}
          phoneNumber={phone}
          loadingHandler={setLoading}
          phoneHandler={phoneVerificationHandler}
          otpMethod={otpMethod}
        />
      )}

      {children}

      <Footer />

      <CookieConsent
        location="bottom"
        enableDeclineButton
        buttonText={t("Cookie-Consent-Accept")}
        cookieName="inPlayTips-cookie-consent"
        containerClasses="cookie-consent-main"
        buttonClasses={"cookie-consent-accept-button"}
        expires={150}
        declineButtonText={t("Cookie-Consent-Decline")}
        declineButtonClasses={"cookie-consent-decline-button"}
        onDecline={() => {
          navigate(ROUTE_CONSTANTS.COOKIE);
        }}
        buttonWrapperClasses={"cookie-consent-button-wrapper"}
      >
        <div className="cookie-container">
          <CookieIcon />
          <h3>Cookies</h3>
          <span>{t("Cookie-Consent-Content")}</span>
        </div>
      </CookieConsent>

      {verifying && <LoggingInLoader />}

      <TermsOfService
        id="termsOfService"
        isOpened={isTermsOpened}
        setIsOpened={setIsTermsOpened}
      />
    </div>
  );
};

export default MainWrapper;
