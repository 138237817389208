import React, { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { ArrowUpOutlined, CloseOutlined } from "@ant-design/icons";
import { Skeleton } from "antd";
import { Link } from "react-router-dom";
import { Button } from "antd";

//assets
import { ReactComponent as LogoIcon } from "../Assests/Images/logo.svg";

//services, hooks and services
import AccountingService from "../Services/Accounting/AccountingService";
import SubscriptionService from "../Services/Subscription/SubscriptionService";
import useMatchMedia from "../Hooks/useMatchMedia";
import { SubscriptionProps } from "../Models/Subscription";
import AuthService from "../Services/Auth/AuthService";
import ProfileService from "../Services/Profile/ProfileService";

//utils and helpers
import TokenHandler from "../Utils/TokenHandler";
import storageHandler from "../Utils/StorageHandler";
import Toast from "../Utils/ToastHandler";

//constants
import { ROUTE_CONSTANTS } from "../Routes/RouteConstants";
import {
  TABLET_MAX_WIDTH_QUERY,
  TOAST_BOTTOM_CENTER,
  TOAST_DURATION,
} from "../Constants/Values";

//components
import TermsOfService from "../Pages/Terms";
import Footer from "../Components/Resources/Footer/Footer";
import ProfileSide from "../Components/ProfileSide/ProfileSide";
import ConfirmationAlert from "../Components/ConfirmationAlert";
import Popup from "../Components/Popup/Popup";
import LogoutLink from "../Components/LogoutLink/LogoutLink";

//style
import "./PrivateWrapper.scss";

const { REACT_APP_PAYMENT_MODEL } = (window as any).__env__;

type Props = {
  children: React.ReactNode;
};

const PrivateWrapper = ({ children }: Props) => {
  const { t } = useTranslation();
  const externalReference =
    TokenHandler?.getDecodedToken()?.UserExternalReference;
  const [balance, setBalance] = useState("0");
  const [loading, setLoading] = useState<boolean>(false);
  const [isTermsOpened, setIsTermsOpened] = useState<boolean>(false);
  const { match: isMobileDevice } = useMatchMedia(TABLET_MAX_WIDTH_QUERY);
  const [showCancelSubscription, setShowCancelSubscription] =
    useState<boolean>(false);

  const [subscription, setSubscription] = useState<SubscriptionProps>({
    startingPaymentDate: "",
    name: "",
    durationInMonth: 0,
    currencyIso3: "",
    amount: 0,
    expired: false,
    autoRenewable: false,
    inGracePeriod: false,
    defaultRegistration: false,
  });

  const [userName, setUserName] = useState<any>({
    firstName: "",
    lastName: "",
  });

  const [isProfileTab, setIsProfileTab] = useState<boolean>(false);

  const [contactInfo, setContactInfo] = useState({
    phone: "",
    email: "",
    oldEmail: "",
    emailVerified: false,
  });

  const [showVerificationAlert, setShowVerificationAlert] =
    useState<boolean>(false);

  const [verificationPopupVisible, setVerificationPopupVisibility] =
    useState<boolean>(false);
  const [otp, setOtp] = useState<string>("");

  const getBalanceHandler = () => {
    setLoading(true);
    AccountingService.getBalance()
      .then((response) => {
        setBalance(response);
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const subscriptionStatusHandler = () => {
    const subscriptionName = subscription.name;
    const subscriptionIsExpired = subscription.expired;

    if (subscriptionIsExpired) {
      return t("profile-subscription-Suspended"); //if its suspended, then its suspended
    } else {
      return t(subscriptionName);
    }
  };

  const subscriptionButtonHandler = (buttonClassName: string) => {
    const subscriptionIsExpired = subscription.expired;
    const subscriptionAutoRenewable = subscription.autoRenewable;
    const defaultRegistration = subscription.defaultRegistration;

    if (subscriptionIsExpired || defaultRegistration) {
      return (
        <button
          className={buttonClassName}
          onClick={() => {
            window.location.href = ROUTE_CONSTANTS.TOP_UP;
          }}
        >
          <span className="text-sm font-sans font-medium text-white">
            {t("profile-subscribe-button")}
          </span>
        </button>
      );
    } else if (
      !subscriptionIsExpired &&
      !defaultRegistration &&
      subscription.name !== ""
    ) {
      return (
        <button
          className={`${buttonClassName} ${
            subscriptionAutoRenewable ? "" : "cursor-not-allowed"
          }`}
          onClick={() => {
            setShowCancelSubscription(true);
          }}
          disabled={!subscriptionAutoRenewable}
        >
          <span className="text-sm font-sans font-medium text-white">
            {t("profile-subscription-Cancel")}
          </span>
        </button>
      );
    } else {
      return null;
    }
  };

  const cancelSubscriptionHandler = () => {
    setLoading(true);
    SubscriptionService.cancelSubscription()
      .then(() => {
        setLoading(false);
        Toast.success(
          t("profile-cancel-subscription-success"),
          TOAST_DURATION,
          TOAST_BOTTOM_CENTER
        );
        getCurrentSubscription();
      })
      .catch(() => {
        setLoading(false);
        Toast.error(
          t("profile-cancel-subscription-failure"),
          TOAST_DURATION,
          TOAST_BOTTOM_CENTER
        );
      });
  };

  const getCurrentSubscription = () => {
    setLoading(true);
    SubscriptionService.getCurrentSubscription()
      .then((response: any) => {
        setSubscription({
          startingPaymentDate: response?.startingPaymentDate,
          name: response?.name,
          durationInMonth: response?.durationInMonth,
          currencyIso3: response?.currencyIso3,
          amount: response?.amount,
          expired: response?.expired || false,
          inGracePeriod: response?.inGracePeriod || false,
          autoRenewable: response?.autoRenewable || false,
          defaultRegistration: response?.defaultRegistration || false,
        });
        setLoading(false);
      })
      .catch(() => {
        setLoading(false);
      });
  };

  const subscriptionActionHandler = () => {
    if (REACT_APP_PAYMENT_MODEL !== "CREDIT") {
      getCurrentSubscription();
    } else {
      getBalanceHandler();
    }
  };

  const getContactInfoHandler = () => {
    setLoading(true);
    AuthService.getEmail()
      .then((response) => {
        setContactInfo({
          phone: response.phone || "",
          email: response.email || "",
          oldEmail: response.email || "",
          emailVerified: response.emailVerified || false,
        });
        setLoading(false);
      })
      .catch((error) => {
        setLoading(false);
        Toast.error(t("generic-error"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
      });
  };

  const verifyEmailHandler = () => {
    setLoading(true);

    ProfileService.verifyEmail(otp)
      .then(() => {
        setLoading(false);
        setVerificationPopupVisibility(false);
        getContactInfoHandler();
        Toast.success(
          t("Profile-Email-Verification-Success"),
          TOAST_DURATION,
          TOAST_BOTTOM_CENTER
        );
      })
      .catch((err) => {
        const error = err.response.data.Error.Message;

        if (error === "InvalidCode") {
          Toast.error(t("InvalidCode"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
        } else {
          Toast.error(t("generic-error"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
        }
        setLoading(false);
      });
  };

  const getEmailVerification = (resend: boolean = false) => {
    setLoading(true);
    ProfileService.getEmailOtp(contactInfo.email)
      .then(() => {
        setLoading(false);
        if (!resend) {
          setVerificationPopupVisibility(true);
        }

        Toast.success(
          t("Profile-Email-Verification-OTP-Sent"),
          TOAST_DURATION,
          TOAST_BOTTOM_CENTER
        );
      })
      .catch((err) => {
        setLoading(false);

        const error = err.response.data.Error.Message;

        if (!resend && error !== "InvalidEmail") {
          setVerificationPopupVisibility((prev) => !prev);
        }

        if (error === "NotAllowedToEditEmailIn5Minutes") {
          Toast.success(
            t("NotAllowedToEditEmailIn5Minutes"),
            TOAST_DURATION,
            TOAST_BOTTOM_CENTER
          );
        } else if (error === "InvalidEmail") {
          Toast.error(t("InvalidEmail"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
        } else {
          Toast.error(t("generic-error"), TOAST_DURATION, TOAST_BOTTOM_CENTER);
        }
      });
  };

  useEffect(() => {
    subscriptionActionHandler();
    getContactInfoHandler();

    const urlParams = new URLSearchParams(window.location.search);
    const tab = urlParams.get("tab");
    if (tab === "profile") {
      setIsProfileTab(true);
    }
  }, []);

  useEffect(() => {
    if (!contactInfo.emailVerified && contactInfo.email) {
      setShowVerificationAlert(true);
    }
  }, [contactInfo.emailVerified, contactInfo.email]);

  return (
    <div
      style={{
        background: !isMobileDevice
          ? "linear-gradient(90deg, #FFFFFF 65%, #F0F0F0 35%)"
          : "#FFFFFF",
      }}
    >
      {showVerificationAlert && !contactInfo.emailVerified && (
        <div className="verification-needed-alert xl:px-16 xl:pt-3">
          <div className="alert alert-warning xl:mb-3 d-flex justify-content-between align-items-center">
            <span className="text-orange">
              {t("dashboard-verification-needed-alert")}
            </span>
            <Button
              onClick={() => getEmailVerification(false)}
              className="text-orange"
              disabled={loading}
            >
              {t("dashboard-verification-resend-button")}
            </Button>
          </div>
        </div>
      )}
      <div className="xl:px-16">
        <div className={`col xl:flex`}>
          <div className="col-md-12 col-sm-12 col-lg-12 col-xl-9">
            <LogoIcon className="mt-7 hidden xl:block" />
            <div className="border-t-2 border-gray-light mt-3 hidden xl:block" />

            <div className="xl:hidden">
              <div className="bg-blue w-full h-[257px] mobile-header">
                <div className={`h-full w-full px-7`}>
                  <div
                    className={`flex-row justify-center gap-3 items-center mt-12 mb-7  ${
                      window.location.pathname === ROUTE_CONSTANTS.TOP_UP
                        ? "hidden"
                        : "flex "
                    }`}
                  >
                    <button
                      className={`rounded-full py-[12px] w-48 flex items-center justify-center ${
                        isProfileTab
                          ? "bg-white text-blue-lighter border-2 border-white"
                          : "bg-transparent border-2 border-white text-white"
                      }`}
                      onClick={() => setIsProfileTab(true)}
                    >
                      <span
                        className={`text-base font-sans font-normal ${
                          isProfileTab ? "text-blue-lighter" : "text-white"
                        } `}
                      >
                        {t("private-wrapper-profile")}
                      </span>
                    </button>

                    <button
                      className={`rounded-full py-[12px] w-48 flex items-center justify-center ${
                        !isProfileTab
                          ? "bg-white text-blue-lighter border-2 border-white"
                          : "bg-transparent border-2 border-white text-white"
                      }`}
                      onClick={() => setIsProfileTab(false)}
                    >
                      <span
                        className={`text-base font-sans font-normal ${
                          !isProfileTab ? "text-blue-lighter" : "text-white"
                        } `}
                      >
                        {t("private-wrapper-game-records")}
                      </span>
                    </button>
                  </div>

                  {window.location.pathname === ROUTE_CONSTANTS.TOP_UP && (
                    <div className="flex flex-row justify-between items-center mt-4 mb-1">
                      <div></div>
                      <h5 className="text-base font-sans font-medium text-white">
                        {REACT_APP_PAYMENT_MODEL === "CREDIT"
                          ? t("topup-title")
                          : t("topup-pricing-title")}
                      </h5>
                      <CloseOutlined
                        rev={undefined}
                        onClick={() => {
                          window.location.href = ROUTE_CONSTANTS.DASHBOARD;
                        }}
                        className="hand-pointer text-white"
                      />
                    </div>
                  )}

                  {REACT_APP_PAYMENT_MODEL === "CREDIT" ? (
                    <div className="ps-2">
                      <h5 className="text-sm font-sans font-medium text-white">
                        {t("private-wrapper-balance")}
                      </h5>

                      <div className="flex items-center justify-between">
                        <div className="flex flex-col">
                          {!loading ? (
                            <div className="flex">
                              <h5 className="text-5xl font-sans font-black text-white p-0 m-0">
                                {balance || 0}
                              </h5>
                              <ArrowUpOutlined
                                rev={undefined}
                                className="text-lemon-green"
                              />
                            </div>
                          ) : (
                            <Skeleton.Input size="large" active={true} />
                          )}
                        </div>

                        <button
                          className="flex bg-white-50 items-center justify-center bg-gray-darker rounded-full w-32 py-[15px]"
                          onClick={() => {
                            window.location.href = ROUTE_CONSTANTS.TOP_UP;
                          }}
                        >
                          <span className="text-sm font-sans font-medium text-white">
                            {t("topup-title")}
                          </span>
                        </button>
                      </div>
                    </div>
                  ) : (
                    <div className="ps-2">
                      <h5 className="text-sm font-sans font-medium text-white">
                        {t("profile-subscription-status")}
                      </h5>

                      <div className="flex items-center justify-between">
                        <div className="flex flex-col">
                          {!loading ? (
                            <div className="flex">
                              <h5 className="text-4xl font-sans font-black text-white p-0 m-0">
                                {subscriptionStatusHandler()}
                              </h5>
                            </div>
                          ) : (
                            <Skeleton.Input size="large" active={true} />
                          )}
                        </div>

                        {subscriptionButtonHandler(
                          "flex bg-white-50 items-center justify-center bg-gray-darker rounded-full w-32 py-[15px]"
                        )}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>

            {isProfileTab && isMobileDevice ? (
              <div className="px-3 w-full">
                <ProfileSide
                  setUserName={setUserName}
                  loading={loading}
                  setLoading={setLoading}
                  fetchBalance={getBalanceHandler}
                  currentSubscription={subscription}
                  contactInfo={contactInfo}
                  setContactInfo={setContactInfo}
                  onEmailVerificationNeeded={() =>
                    setShowVerificationAlert(true)
                  }
                  getContactInfoHandler={getContactInfoHandler}
                  getEmailVerification={getEmailVerification}
                />
                <div className="flex justify-center items-center my-8">
                  <LogoutLink />
                </div>
              </div>
            ) : (
              children
            )}
          </div>

          {/*hidden on mobile and tablet and only visible on desktop*/}
          <div className="col-lg-3 col-md-4 col-sm-4 hidden xl:block">
            {externalReference && (
              <div className="bg-blue p-4 rounded-[25px] mt-20">
                <div className="flex flex-col justify-center items-center">
                  <h5 className="text-white mt-3 text-lg font-sans font-medium">
                    {loading ? (
                      <Skeleton.Input size="default" active={true} />
                    ) : (
                      `${userName.firstName} ${userName.lastName}`
                    )}
                  </h5>

                  {REACT_APP_PAYMENT_MODEL === "CREDIT" ? (
                    <div className="flex flex-col justify-center items-center">
                      <div className="mt-8">
                        <h5 className="text-sm font-sans font-medium text-white">
                          {t("private-wrapper-balance")}
                        </h5>

                        <div className="flex">
                          {!loading ? (
                            <div className="flex">
                              <h5
                                id="balance-text"
                                className="text-4xl font-sans font-black text-white"
                              >
                                {balance || 0}
                              </h5>
                              <ArrowUpOutlined
                                rev={undefined}
                                className="text-lemon-green"
                              />
                            </div>
                          ) : (
                            <Skeleton.Input size="default" active={true} />
                          )}
                        </div>
                      </div>

                      <button
                        className="flex bg-white-50 items-center bg-gray-darker rounded-full px-20 py-3 mt-8 mb-3"
                        id={
                          REACT_APP_PAYMENT_MODEL === "CREDIT"
                            ? "topup-btn"
                            : "subscription-button"
                        }
                        onClick={() => {
                          window.location.href = ROUTE_CONSTANTS.TOP_UP;
                        }}
                      >
                        <span className="text-sm font-sans font-medium text-white">
                          {REACT_APP_PAYMENT_MODEL === "CREDIT"
                            ? t("topup-title")
                            : t("topup-pricing-title")}
                        </span>
                      </button>
                    </div>
                  ) : (
                    <div className="flex flex-col justify-center items-center">
                      <div className="mt-8">
                        <h5 className="text-sm font-sans font-medium text-white">
                          {t("profile-subscription-status")}
                        </h5>

                        <div className="flex">
                          {!loading ? (
                            <div className="flex">
                              <h5 className="text-3xl font-sans font-black text-white uppercase">
                                {subscriptionStatusHandler()}
                              </h5>
                            </div>
                          ) : (
                            <Skeleton.Input size="default" active={true} />
                          )}
                        </div>
                      </div>

                      {subscriptionButtonHandler(
                        "flex bg-white-50 items-center bg-gray-darker rounded-full px-20 py-3 mt-8 mb-3"
                      )}
                    </div>
                  )}
                </div>
              </div>
            )}

            <ProfileSide
              setUserName={setUserName}
              loading={loading}
              setLoading={setLoading}
              fetchBalance={getBalanceHandler}
              currentSubscription={subscription}
              contactInfo={contactInfo}
              setContactInfo={setContactInfo}
              onEmailVerificationNeeded={() => setShowVerificationAlert(true)}
              getContactInfoHandler={getContactInfoHandler}
              getEmailVerification={getEmailVerification}
            />

            <div className="flex justify-center items-center my-8">
              <LogoutLink />
            </div>
          </div>
        </div>
      </div>

      <ConfirmationAlert
        id="cancelSubscription"
        isVisible={showCancelSubscription}
        setIsVisible={setShowCancelSubscription}
        bodyText={t("profile-cancel-subscription-message")}
        onConfirmation={() => cancelSubscriptionHandler()}
        confirmButtonText={t("profile-cancel-subscription-yes")}
        cancelButtonText={t("profile-cancel-subscription-no")}
      />

      <Footer />

      {/*{loading && <div className="spinner-container backdrop-blur-md">*/}
      {/*    <Spin size="large"/>*/}
      {/*</div>}*/}

      <TermsOfService
        id="termsOfService"
        isOpened={isTermsOpened}
        setIsOpened={setIsTermsOpened}
      />

      {verificationPopupVisible && (
        <Popup
          isOpen={verificationPopupVisible}
          setIsOpen={setVerificationPopupVisibility}
        >
          <div className="col-md-12 my-4">
            <label htmlFor="otp" className="col-form-label">
              <span className=" font-sans text-xl">
                {t("Profile-Enter-Otp")}
              </span>
              <span className="text-error"> *</span>
            </label>

            <div className="input-group">
              <input
                id="otp"
                type="number"
                className="form-control"
                placeholder="Enter OTP"
                aria-label="otp"
                aria-describedby="email-resend"
                value={otp}
                disabled={contactInfo.emailVerified}
                onChange={(e) => {
                  const value = e.target.value;
                  if (/^\d*$/.test(value)) {
                    setOtp(value);
                  }
                }}
              />

              <button
                className="btn btn-sm btn-outline-dark"
                type="button"
                id="email-resend"
                disabled={loading}
                onClick={() => getEmailVerification(true)}
              >
                {t("profile-side-resend-otp")}
              </button>
            </div>

            <div className="alert alert-success mt-3 mb-2 d-flex justify-content-between align-items-center">
              <span className="text-green font-sans text-base">
                {t("Profile-Success-Message")}
              </span>
            </div>
            <button
              id="saveButton"
              className={`bg-blue text-white font-sans text-sm font-normal rounded-xl px-6 py-2 rounded-full mt-3 ${
                loading || otp === "" ? "opacity-50 cursor-not-allowed" : ""
              }`}
              type="button"
              onClick={verifyEmailHandler}
              disabled={loading || otp === ""}
            >
              {t("profile-side-verify-email")}
            </button>
          </div>
        </Popup>
      )}
    </div>
  );
};

export default PrivateWrapper;
