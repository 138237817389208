import React, { useEffect, useRef, useState } from "react";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import { useNavigate } from "react-router";

import TermsOfService from "../Terms";
import OtpPopup from "../../Components/OtpPopup/OtpPopup";

import AssetsService from "../../Services/Assets/AssetsService";
import AuthService from "../../Services/Auth/AuthService";

import {
  EMAIL_PATTERN,
  TOAST_BOTTOM_CENTER,
  TOAST_DURATION,
} from "../../Constants/Values";
import Toast from "../../Utils/ToastHandler";

import { gtagEventHandler } from "../../Utils/gtagEventHandler";
import { hidePhoneNumber } from "../../Utils/hidePhoneNumber";

import "./Styles.scss";
import TelegramBotPopup from "./TelegramBotPopup/TelegramBotPopup";

type FormData = {
  phone: string;
  email: string;
};

const Register = () => {
  const phoneInputRef = useRef<any>(null);
  const navigate = useNavigate();
  const { t, i18n } = useTranslation();
  const [isOtpPopupOpen, setIsOtpPopupOpen] = useState<boolean>(false);
  const [registered, setRegistered] = useState(false);
  const [otpMethod, setOtpMethod] = useState<string>("");
  const [phone, setPhone] = useState<string>("");

  const {
    control,
    register,
    handleSubmit,
    formState: { errors, isValid, isDirty },
  } = useForm<any>({
    mode: "onChange",
  });

  const [termsAndConditions, setTermsAndConditions] = useState({
    terms: false,
    credit: false,
  });
  const [isTermsOpened, setIsTermsOpened] = useState(false);

  const [failedTerms, setFailedTerms] = useState(false);
  const [failedCredit, setFailedCredit] = useState(false);
  const [region, setRegion] = useState<any>(null);
  const [loading, setLoading] = useState<boolean>(false);
  const [phoneStep, setPhoneStep] = useState(1);
  const [showTelegramBotStep, setShowTelegramBotStep] = useState(false);

  const getRegionHandler = () => {
    AssetsService.getRegion()
      .then((res: any) => {
        setRegion(res?.region);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const submitForm: SubmitHandler<FormData> = (data: FormData) => {
    if (registered) {
      loginHandler(data.phone);
    } else {
      if (phoneStep === 1) {
        setPhoneStep(2);
        return;
      }

      setFailedTerms(!termsAndConditions.terms);
      setFailedCredit(!termsAndConditions.credit);
      if (!termsAndConditions.terms || !termsAndConditions.credit) {
        Toast.error(
          t("Error-Accept-Terms-And-Credits"),
          TOAST_DURATION,
          TOAST_BOTTOM_CENTER
        );
        return;
      }
      const phonePayload = data.phone.replace(/\D/g, "");
      setPhone(data.phone);
      setLoading(true);
      AuthService.registerPhoneNumber(phonePayload, data.email)
        .then((res) => {
          console.log("res", res.Result);

          const response = res.Result;

          setOtpMethod(response);

          if (response === "PartialRegistration") {
            setShowTelegramBotStep(true);
          } else {
            setIsOtpPopupOpen(true);
          }

          setLoading(false);
        })
        .catch((err) => {
          const error = err?.response?.data?.Error?.Message || "";

          setLoading(false);
          setPhoneStep(1);

          if (error && error === "RegistrationError") {
            Toast.error(
              t("Registration-Error-Register"),
              TOAST_DURATION,
              TOAST_BOTTOM_CENTER
            );
          } else if (error && error === "PartialRegistrationError") {
            setShowTelegramBotStep(true);
          } else {
            Toast.error(
              error && i18n.exists(error) ? t(error) : t("generic-error"),
              TOAST_DURATION,
              TOAST_BOTTOM_CENTER
            );
          }
        });
    }
  };

  const loginHandler = (phoneNumber: string) => {
    const phonePayload = phoneNumber.replace(/\D/g, "");

    setPhone(phoneNumber);
    setLoading(true);
    AuthService.authorizePhoneNumber(phonePayload)
      .then((res) => {
        const response = res.Result;

        setOtpMethod(response);
        setLoading(false);

        if (response === "PartialRegistration") {
          setShowTelegramBotStep(true);
        } else {
          setIsOtpPopupOpen(true);
        }

        gtagEventHandler(
          {
            event_category: "Registration iframe - Login",
            event_category_description: "Phone Verification Success",
            value: "otp-sent-" + res + " - " + hidePhoneNumber(phoneNumber),
            mobile: phoneNumber,
            event_label: "Phone Verification - OTP Sent",
          },
          "phone_verification_otp_sent"
        );
      })
      .catch((err) => {
        setLoading(false);

        const error = err?.response?.data?.Error?.Message || "";

        gtagEventHandler(
          {
            event_category: "Registration iframe - Login",
            event_category_description: "Phone Verification Error",
            value: "otp-sent-" + error + " - " + hidePhoneNumber(phoneNumber),
            mobile: phoneNumber,
            event_label: "Phone Verification Failed",
          },
          "phone_verification_failed"
        );

        if (error && error === "PartialRegistrationError") {
          setShowTelegramBotStep(true);
        } else if (error && error === "RegistrationError") {
          Toast.error(
            t("Registration-Error-Login"),
            TOAST_DURATION,
            TOAST_BOTTOM_CENTER
          );
        } else {
          Toast.error(
            error && i18n.exists(error) ? t(error) : t("generic-error"),
            TOAST_DURATION,
            TOAST_BOTTOM_CENTER
          );
        }
      });
  };

  useEffect(() => {
    getRegionHandler();
  }, []);

  return (
    <div className="register-container">
      <div className="register-inner py-4">
        <h4 className="text-center font-normal leading-10 xl:mb-5 text-blue-dark text-3xl font-sans">
          {registered ? t("Login") : t("Register")}
        </h4>

        {!registered && (
          <span className="register-subheading mb-2 text-center text-blue-dark">
            {phoneStep === 1
              ? t("Registration-Step-One-Title")
              : t("Registration-Step-Two-Title")}
          </span>
        )}
        <div
          className={`d-flex align-items-center justify-content-center flex-column mx-6 text-blue-dark ${
            phoneStep === 1 && "mb-5"
          }`}
        >
          <Controller
            control={control}
            name="phone"
            rules={{
              required: {
                value: true,
                message: "Phone is required",
              },
              validate: isPossiblePhoneNumber,
            }}
            render={({ field: { onChange, value } }) => {
              return (
                <div className="flex justify-between items-center w-full bg-gray-dark rounded-full hide-focus mx-6">
                  {phoneStep === 1 ? (
                    <>
                      <PhoneInput
                        ref={phoneInputRef}
                        addInternationalOption={false}
                        value={value}
                        onChange={onChange}
                        defaultCountry={region ? region : "MT"}
                        id={
                          !registered
                            ? "register-phone-input"
                            : "login-phone-input"
                        }
                        placeholder={"Phone Number"}
                        className="w-full rounded-full p-3 bg-transparent"
                        limitMaxLength
                      />
                      <button
                        type="submit"
                        className="bg-blue text-white font-sans text-sm font-semibold rounded-xl px-6 py-3 m-2 rounded-full w-40"
                        id={
                          !registered
                            ? "register-form-button"
                            : "login-form-button"
                        }
                        disabled={!isDirty || !isValid}
                        onClick={handleSubmit(submitForm)}
                      >
                        {loading
                          ? "Loading "
                          : !registered
                          ? "Next "
                          : "Login "}
                      </button>
                    </>
                  ) : (
                    <>
                      <input
                        placeholder={"Enter Email"}
                        type="email"
                        className="email-input w-full rounded-full p-3 bg-transparent"
                        {...register("email", {
                          required: {
                            value: true,
                            message: "Email is required",
                          },
                          pattern: {
                            value: EMAIL_PATTERN,
                            message: "Invalid email address",
                          },
                        })}
                      />
                      <button
                        type="submit"
                        id={
                          !registered
                            ? "register-form-button"
                            : "login-form-button"
                        }
                        disabled={!isDirty || !isValid}
                        className="bg-blue text-white font-sans text-sm font-semibold rounded-xl px-6 py-3 m-2 rounded-full w-40"
                        onClick={handleSubmit(submitForm)}
                      >
                        {loading
                          ? "Loading "
                          : !registered
                          ? "Register "
                          : "Login "}
                      </button>
                    </>
                  )}
                </div>
              );
            }}
          />

          {errors.phone && (
            <div className="text-error " id="phone-validation-error">
              {errors["phone"].message + ""}
            </div>
          )}
          {errors.phone?.type === "validate" && (
            <div className="text-error " id="phone-invalid-error">
              Invalid phone number
            </div>
          )}
          {errors.email && (
            <div className="text-error " id="email-validation-error">
              {errors["email"].message + ""}
            </div>
          )}

          <div
            className={`login-registration-question 
                            ${phoneStep === 1 ? "mt-2 mb-2" : "mt-2"}
                            `}
          >
            <div className="flex flex-col">
              <span
                className="text-blue font-sans font-normal text-sm"
                onClick={() => {
                  setRegistered(!registered);
                  setPhoneStep(1);
                }}
              >
                {!registered ? "Already registered?" : "Not registered?"} Start
                Here
              </span>
            </div>
          </div>

          {!registered && phoneStep === 2 && (
            <div className="login-register-terms-and-credits">
              <div>
                <span className="text-error pe-1">*</span>
                <div className="form-check form-check-inline tex">
                  <input
                    className={
                      failedTerms
                        ? "form-check-input registration-checkbox-error"
                        : "form-check-input"
                    }
                    type="checkbox"
                    id="registration-terms-checkbox"
                    onClick={() => {
                      setTermsAndConditions({
                        ...termsAndConditions,
                        terms: !termsAndConditions.terms,
                      });
                    }}
                    value="option1"
                  />
                  <label
                    id="registration-terms-checkbox-label"
                    className={
                      failedTerms
                        ? "form-check-label login-register-terms-and-conditions  registration-checkbox-error  font-sans text-base"
                        : "form-check-label login-register-terms-and-conditions text-blue-dark font-sans text-base"
                    }
                    htmlFor="login-registration-terms-checkbox"
                  >
                    Accept
                    <span
                      className={
                        failedTerms
                          ? "text-decoration-underline login-register-terms-and-conditions registration-checkbox-error ps-1  font-sans text-base"
                          : "text-decoration-underline login-register-terms-and-conditions ps-1 text-blue-dark font-sans text-base"
                      }
                      onClick={() => setIsTermsOpened(!isTermsOpened)}
                    >
                      Terms and Conditions
                    </span>
                  </label>
                </div>
              </div>

              <div>
                <span className="text-error pe-1">*</span>
                <div className="form-check form-check-inline">
                  <input
                    className={
                      failedCredit
                        ? "form-check-input registration-checkbox-error"
                        : "form-check-input text-blue-dark"
                    }
                    type="checkbox"
                    id="credit-checkboxs"
                    onClick={() => {
                      setTermsAndConditions({
                        ...termsAndConditions,
                        credit: !termsAndConditions.credit,
                      });
                    }}
                    value="option2"
                  />
                  <label
                    className={
                      failedCredit
                        ? "form-check-label login-register-credit registration-checkbox-error font-sans text-base"
                        : "form-check-label login-register-credit text-blue-dark font-sans text-base"
                    }
                    htmlFor="credit-checkbox"
                  >
                    Receive messages and free credits
                  </label>
                </div>
              </div>
            </div>
          )}
        </div>

        {isOtpPopupOpen && (
          <OtpPopup
            isOpen={isOtpPopupOpen}
            setIsOpen={setIsOtpPopupOpen}
            loading={loading}
            nextPageHandler={() => {}}
            phoneNumber={phone}
            loadingHandler={setLoading}
            phoneHandler={loginHandler}
            otpMethod={otpMethod}
            isRegistrationIframe={true}
          />
        )}

        {showTelegramBotStep && (
          <TelegramBotPopup
            isOpen={showTelegramBotStep}
            setIsOpen={setShowTelegramBotStep}
          />
        )}

        <TermsOfService
          id="termsOfService"
          isOpened={isTermsOpened}
          setIsOpened={setIsTermsOpened}
        />
      </div>
    </div>
  );
};

export default Register;
