import Popup from "../../../Components/Popup/Popup";
import TelegramStep from "../../../Components/Registration/TelegramStep/TelegramStep";

type Props = {
    isOpen: boolean;
    setIsOpen: React.Dispatch<React.SetStateAction<boolean>>;
}
const TelegramBotPopup = ({isOpen, setIsOpen}:Props) => {
    return (<Popup
        isOpen={isOpen}
        setIsOpen={() => setIsOpen(false)}
        id="telegram-bot-popup"
        customClass="telegram-bot-popup"
        hasCustomHeader={false}
    >
        <TelegramStep/>
    </Popup>);
}

export default TelegramBotPopup;