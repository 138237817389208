import React, { useEffect, useRef, useState } from "react";
import { Link } from "react-router-dom";
import { Controller, SubmitHandler, useForm } from "react-hook-form";
import PhoneInput, { isPossiblePhoneNumber } from "react-phone-number-input";
import "react-phone-number-input/style.css";

import {
  EMAIL_PATTERN,
  MOBILE_MAX_WIDTH_QUERY,
  TOAST_BOTTOM_CENTER,
  TOAST_DURATION,
} from "../../Constants/Values";

import "./Style.scss";
import Popup from "../Popup/Popup";
import CustomPopup from "../CustomPopup/CustomPopup";
import useMatchMedia from "../../Hooks/useMatchMedia";
import TelegramStep from "../Registration/TelegramStep/TelegramStep";
import { gtagEventHandler } from "../../Utils/gtagEventHandler";
import { hidePhoneNumber } from "../../Utils/hidePhoneNumber";
import Toast from "../../Utils/ToastHandler";
import AssetsService from "../../Services/Assets/AssetsService";
import { useTranslation } from "react-i18next";
import AuthService from "../../Services/Auth/AuthService";
import { ROUTE_CONSTANTS } from "../../Routes/RouteConstants";
import { useNavigate } from "react-router";
import TermsOfService from "../../Pages/Terms";
import { toggleFreshChatWidget } from "../../Utils/toggleFreshChatWidget";
import { useAppData } from "../../Context/AppContext";

type FormData = {
  phone: string;
  email: string;
};

const Registration = () => {
  const phoneInputRef = useRef<any>(null);
  const [step, setStep] = useState(1);
  const { match: isMobileDevice } = useMatchMedia(MOBILE_MAX_WIDTH_QUERY);
  const [otpPopup, setOtpPopup] = useState(true);
  const [isTermsOpened, setIsTermsOpened] = useState(false);
  const [isOtpPopupOpen, setIsOtpPopupOpen] = useState(false);

  const {
    control,
    register,
    handleSubmit,
    setError,
    formState: { errors, isValid, isDirty },
  } = useForm<any>({
    mode: "onChange",
  });

  const { t, i18n } = useTranslation();
  const [phoneStep, setPhoneStep] = useState(1);
  const [failedTerms, setFailedTerms] = useState(true);
  const [failedCredit, setFailedCredit] = useState(true);
  // const [termsAndConditions, setTermsAndConditions] = useState({
  //     terms: false,
  //     credit: false,
  // });
  const [phone, setPhone] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);
  const [otpMethod, setOtpMethod] = useState<string>("");
  const navigate = useNavigate();
  const [region, setRegion] = useState<any>(null);
  const { showPricingPage } = useAppData();

  const registerPhoneHandler = (
    phoneNumber: string,
    step: number,
    email: string
  ) => {
    const phonePayload = phoneNumber.replace(/\D/g, "");
    setPhone(phoneNumber);
    setLoading(true);
    AuthService.registerPhoneNumber(phonePayload, email)
      .then((res) => {
        const resData = res.Result;

        if (resData === "PartialRegistration") {
          setStep(3);
        } else {
          setOtpMethod(resData);
          setLoading(false);
          setStep(step);

          Toast.success(
            t("otp-sent-" + resData),
            TOAST_DURATION,
            TOAST_BOTTOM_CENTER
          );

          gtagEventHandler(
            {
              event_category: "Registration",
              event_category_description: "Phone Verification Success",
              value: "otp-sent-" + res + " - " + hidePhoneNumber(phoneNumber),
              mobile: phoneNumber,
              event_label: "Phone Verification - OTP Sent",
            },
            "phone_verification_otp_sent"
          );
        }
      })
      .catch((err) => {
        const error = err?.response?.data?.Error?.Message || "";

        setPhoneStep(1);
        setLoading(false);
        setFailedCredit(true);
        setFailedTerms(true);

        gtagEventHandler(
          {
            event_category: "Registration",
            event_category_description: "Phone Verification Error",
            value: "otp-sent-" + error + " - " + hidePhoneNumber(phoneNumber),
            mobile: phoneNumber,
            event_label: "Phone Verification Failed",
          },
          "phone_verification_failed"
        );
        if (error && error === "PartialRegistrationError") {
          setStep(3);
        } else if (error && error === "RegistrationError") {
          Toast.error(
            t("Registration-Error-Register"),
            TOAST_DURATION,
            TOAST_BOTTOM_CENTER
          );
        } else {
          Toast.error(
            error && i18n.exists(error) ? t(error) : t("generic-error"),
            TOAST_DURATION,
            TOAST_BOTTOM_CENTER
          );
        }
      });
  };

  const submitForm: SubmitHandler<FormData> = (data: FormData) => {
    if (phoneStep === 1) {
      gtagEventHandler(
        {
          event_category: "Registration",
          event_category_description: "Phone Home - Step 1",
          value: "phone registration - " + hidePhoneNumber(data.phone),
          mobile: data.phone,
          event_label: "Phone Registration - Step 1",
        },
        "phone_registration_step_1"
      );
      setPhoneStep(2);
      return;
    }

    if (failedTerms || failedCredit) {
      Toast.error(
        t("Error-Accept-Terms-And-Credits"),
        TOAST_DURATION,
        TOAST_BOTTOM_CENTER
      );
      return;
    }

    gtagEventHandler(
      {
        event_category: "Registration",
        event_category_description: "Phone Home - Step 2",
        value:
          "phone registration - " +
          hidePhoneNumber(data.phone) +
          " - " +
          data.email,
        mobile: data.phone,
        email: data.email,
        event_label: "Phone Registration - Step 2",
      },
      "phone_registration_step_2"
    );

    registerPhoneHandler(data.phone, 2, data.email);
    return;
  };

  const phoneVerificationHandler = (phoneNumber: string, step: number) => {
    const phonePayload = phoneNumber.replace(/\D/g, "");
    setPhone(phoneNumber);
    setLoading(true);
    AuthService.authorizePhoneNumber(phonePayload)
      .then((res) => {
        const resData = res.Result;

        if (resData === "PartialRegistration") {
          setStep(3);
        } else {
          setOtpMethod(resData);
          setLoading(false);
          setStep(step);
          Toast.success(
            t("otp-sent-" + resData),
            TOAST_DURATION,
            TOAST_BOTTOM_CENTER
          );
          gtagEventHandler(
            {
              event_category: "Login",
              event_category_description: "Phone Verification Success",
              value: "otp-sent-" + res + " - " + hidePhoneNumber(phoneNumber),
              mobile: phoneNumber,
              event_label: "Phone Verification - OTP Sent",
            },
            "phone_verification_otp_sent"
          );
        }
      })
      .catch((err) => {
        setLoading(false);

        const error = err?.response?.data?.Error?.Message || "";

        gtagEventHandler(
          {
            event_category: "Login",
            event_category_description: "Phone Verification Error",
            value: "otp-sent-" + error + " - " + hidePhoneNumber(phoneNumber),
            mobile: phoneNumber,
            event_label: "Phone Verification Failed",
          },
          "phone_verification_failed"
        );

        if (error && error === "PartialRegistrationError") {
          // navigate(ROUTE_CONSTANTS.TELEGRAM, {state: {phone: phoneNumber}});
          setStep(3);
        } else if (error && error === "RegistrationError") {
          Toast.error(
            t("Registration-Error-Login"),
            TOAST_DURATION,
            TOAST_BOTTOM_CENTER
          );
        } else {
          Toast.error(
            error && i18n.exists(error) ? t(error) : t("generic-error"),
            TOAST_DURATION,
            TOAST_BOTTOM_CENTER
          );
        }
      });
  };

  const getRegionHandler = () => {
    AssetsService.getRegion()
      .then((res: any) => {
        setRegion(res?.region);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  const submitOTPForm = (otp: string) => {
    setLoading(true);
    AuthService.verifyOTP(otp, phone)
      .then(() => {
        setLoading(false);
        setStep(1);

        gtagEventHandler(
          {
            event_category: "OTP",
            event_category_description: "Phone Verification Success",
            value: "otp-verified-" + otpMethod + " - " + hidePhoneNumber(phone),
            mobile: phone,
            event_label: "Phone Verification - OTP Verified",
          },
          "phone_verification_otp_verified"
        );

        showPricingPage()
          ? navigate(ROUTE_CONSTANTS.TOP_UP)
          : navigate(ROUTE_CONSTANTS.DASHBOARD);
      })
      .catch((err) => {
        setLoading(false);
        const error = err.response.data.error;

        gtagEventHandler(
          {
            event_category: "OTP",
            event_category_description: "Phone Verification Error",
            value: "otp-error-" + error + " - " + hidePhoneNumber(phone),
            mobile: phone,
            event_label: "Phone Verification - OTP Error",
          },
          "phone_verification_otp_error"
        );

        if (error === "invalid_grant" || error === "invalid_otp") {
          setError("otp", {
            type: "manual",
            message: t("invalid_grant"),
          });
        } else {
          Toast.error(
            error ? t(error) : t("generic-error"),
            TOAST_DURATION,
            TOAST_BOTTOM_CENTER
          );
        }
      });
  };

  useEffect(() => {
    const inputElem = phoneInputRef.current;
    if (inputElem) {
      inputElem.classList.add("form-control");
      inputElem.classList.add("border-0");
    }

    getRegionHandler();
  }, []);

  return (
    <div className="">
      <div className="relative">
        <div
          className={`mb-10 xl:mb-20 w-full xl:w-[500px] xl:bg-white rounded-[26px] xl:absolute z-10 ${
            phoneStep !== 1
              ? "h-[395px] xl:shadow-lg xl:mt-12 xl:p-8"
              : "xl:mt-10"
          }`}
        >
          {step === 1 && (
            <Controller
              control={control}
              name="phone"
              rules={{
                required: {
                  value: true,
                  message: "Phone is required",
                },
                validate: isPossiblePhoneNumber,
              }}
              render={({ field: { onChange, value } }) => {
                return (
                  <div>
                    <div className="flex justify-between items-center w-full bg-gray-dark rounded-full hide-focus">
                      <PhoneInput
                        ref={phoneInputRef}
                        addInternationalOption={false}
                        value={value}
                        onChange={onChange}
                        defaultCountry={region ? region : "MT"}
                        id={"register-phone-input"}
                        placeholder={"Phone Number"}
                        className="w-full rounded-full p-3 bg-transparent"
                        limitMaxLength
                      />
                      <button
                        type="submit"
                        id="register-next-btn"
                        className="bg-blue text-white font-sans text-sm font-semibold rounded-xl px-6 py-3 m-2 rounded-full w-40"
                        disabled={!isDirty || !isValid}
                        onClick={handleSubmit(submitForm)}
                      >
                        Next
                      </button>
                    </div>

                    <div className="ps-3">
                      <span
                        className="hover-link text-[#20A0E1] underline font-sans font-normal text-sm"
                        onClick={() => toggleFreshChatWidget()}
                      >
                        Contact Support
                      </span>
                      {errors.phone && (
                        <div className="text-error " id="text-error-message">
                          {errors["phone"].message + ""}
                        </div>
                      )}
                      {errors.phone?.type === "validate" && (
                        <div className="text-error " id="phone-invalid-error">
                          Invalid phone number
                        </div>
                      )}
                    </div>

                    {phoneStep === 2 && (
                      <div className="flex justify-between items-center w-full mt-12 bg-gray-dark rounded-full hide-focus">
                        <input
                          {...register("email", {
                            required: {
                              value: true,
                              message: "Email is required",
                            },
                            pattern: {
                              value: EMAIL_PATTERN,
                              message: "Invalid email format",
                            },
                          })}
                          type="email"
                          placeholder="Email"
                          className="w-full ps-3 bg-transparent"
                          id="register-email-input"
                        />
                        <button
                          type="submit"
                          id="register-form-button"
                          className="bg-blue text-white font-sans text-sm font-semibold rounded-xl px-6 py-3 m-2 rounded-full w-40"
                          disabled={!isDirty || !isValid}
                          onClick={handleSubmit(submitForm)}
                        >
                          Sign Up
                        </button>
                      </div>
                    )}

                    {phoneStep === 2 && (
                      <div className="flex items-center justify-center mt-3">
                        <span className="text-gray-dark font-sans font-normal text-sm">{`Step ${phoneStep} / 2`}</span>
                      </div>
                    )}

                    {phoneStep === 2 && (
                      <div className="flex flex-col mt-3 ms-10">
                        <div className="flex items-center">
                          <div className="flex items-center terms-conditions-checkbox">
                            <input
                              type="checkbox"
                              id="terms-checkbox"
                              className="me-3"
                              onClick={() => {
                                setFailedTerms(!failedTerms);
                              }}
                              value="option1"
                            />
                            <label
                              htmlFor="terms-checkbox"
                              className={`text-[#97A3AD] font-sans font-normal text-sm`}
                            >
                              Accept{" "}
                              <Link
                                to=""
                                className={`text-[#20A0E1] underline`}
                                onClick={() => setIsTermsOpened(!isTermsOpened)}
                              >
                                Terms and Conditions
                              </Link>
                            </label>
                          </div>
                        </div>

                        <div className="flex items-center mt-3">
                          <div className="flex items-center terms-conditions-checkbox">
                            <input
                              type="checkbox"
                              id="credit-checkbox"
                              className="me-3"
                              value="option2"
                              onClick={() => {
                                setFailedCredit(!failedCredit);
                              }}
                            />
                            <label
                              htmlFor="credit-checkbox"
                              className={`text-[#97A3AD] font-sans font-normal text-sm`}
                            >
                              Receive Messages and Free Credits
                            </label>
                          </div>
                        </div>
                      </div>
                    )}
                  </div>
                );
              }}
            />
          )}

          {step === 2 && (
            <div className="px-10 pt-8 flex  flex-col justify-start">
              <span className="font-sans text-base font-normal text-gray-darker mb-3">
                To continue, complete this verification step.
              </span>
              <span className="font-sans text-base font-normal text-gray-darker mb-3">
                We've sent a One Time Password (OTP) to the phone {phone} via{" "}
                {otpMethod === "OTPSentViaTelegram" ? "Telegram" : "SMS"}.
              </span>
              <span className="font-sans text-base font-normal text-gray-darker">
                Please enter it below.
              </span>

              <div className="registration-otp">
                <input
                  type="number"
                  max={6}
                  disabled={loading}
                  className={`w-full mt-6 p-4 bg-gray-dark opacity-50 rounded-full font-sans text-base text-center ${
                    loading ? "cursor-not-allowed opacity-70" : ""
                  }`}
                  placeholder="000000"
                  {...register("otp", {
                    pattern: {
                      value: /^[0-9]*$/,
                      message: t("Step-Two-OTP-Only-Numbers"),
                    },
                    required: {
                      value: true,
                      message: t("Step-Two-OTP-Required"),
                    },
                    minLength: {
                      value: 6,
                      message: t("Step-Two-OTP-Must-Be-6-Digits"),
                    },
                    maxLength: {
                      value: 6,
                      message: t("Step-Two-OTP-Cant-Be-More-Than-6-Digits"),
                    },
                    onChange: (e) => {
                      if (e.target.value.length === 6) {
                        submitOTPForm(e.target.value);
                      }
                    },
                  })}
                  maxLength={6}
                  required={true}
                />
                {loading && (
                  <div className="mt-2 flex items-center justify-center">
                    <div className="flex items-center gap-2">
                      <div className="w-4 h-4 border-2 border-blue-500 border-t-transparent rounded-full animate-spin"></div>
                      <span className="text-sm text-blue-500 font-medium">
                        Loading...
                      </span>
                    </div>
                  </div>
                )}
              </div>

              <div
                className="flex justify-center text-error mt-2"
                id="otp-error-message"
              >
                {String(errors.otp?.message || "")}
              </div>

              <Link
                to={"/"}
                className="text-blue font-sans text-sm font-normal mt-3 text-center underline"
                onClick={(e) => {
                  e.preventDefault();

                  gtagEventHandler(
                    {
                      event_category: "OTP",
                      event_category_description: "Resend OTP",
                      value:
                        "resend-otp-" +
                        otpMethod +
                        " - " +
                        hidePhoneNumber(phone),
                      mobile: phone,
                      event_label: "Phone Verification - Resend OTP",
                    },
                    "phone_verification_resend_otp"
                  );

                  phoneVerificationHandler(phone, 2);
                }}
              >
                {otpMethod === "OTPSentViaTelegram"
                  ? t("Resend-OTP")
                  : t("Resend-OTP-Sms")}
              </Link>
            </div>
          )}

          {step === 3 &&
            (isMobileDevice ? (
              otpPopup && (
                <CustomPopup
                  setIsOpen={setOtpPopup}
                  callback={() => setStep(1)}
                  isOpen={otpPopup}
                >
                  <TelegramStep />
                </CustomPopup>
              )
            ) : (
              <TelegramStep />
            ))}
        </div>
      </div>

      <TermsOfService
        id="termsOfService"
        isOpened={isTermsOpened}
        setIsOpened={setIsTermsOpened}
      />
    </div>
  );
};

export default Registration;
