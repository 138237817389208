import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import BallIcon from "../../../Assests/Images/ball.png";

import { getSectionContentByName } from "../../../Utils/getSectionContentByName";
import { useLoginPopUp } from "../../../Context/LoginContext";

const WinningPotential = () => {
  const { t } = useTranslation();
  const [winningPotentialData, setWinningPotentialData] = useState<any>({
    getStartedTitle: "",
    getStartedContent: "",
    bottomSection: null,
  });
  const { isLoginPopupOpen, setIsLoginPopupOpen, setIsRegistered } =
    useLoginPopUp();

  const fetchWinningPotential = async () => {
    const cache = await caches.open("layoutContents");

    const response = await cache.match("layoutContents");

    if (response) {
      const data = await response.json();

      setWinningPotentialData({
        getStartedTitle: data?.GetStartedSection?.translations[0].Title,
        getStartedContent: data?.GetStartedSection?.translations[0].Content,
        bottomSection: getSectionContentByName(data, "BottomSection"),
      });
    } else {
      let retryCounter = 0;

      const retryFetch = async () => {
        if (retryCounter < 1) {
          retryCounter++;
          fetchWinningPotential();
        }
      };

      retryFetch();
    }
  };

  useEffect(() => {
    fetchWinningPotential();
  }, []);

  return (
    <div className="container">
      <div className="my-16 lg:my-24 lg:mx-24 flex flex-col items-center justify-center">
        <h5 className="font-sans text-3xl font-semibold leading-9 text-blue-dark mb-6 text-center">
          {winningPotentialData?.getStartedTitle || ""}
        </h5>
        <span className="font-sans text-base font-base leading-6 text-gray-darkest text-center">
          {winningPotentialData?.getStartedContent || ""}
        </span>

        <button
          className="bg-blue text-white font-sans text-base font-semibold leading-6 rounded-xl px-8 py-3 mt-20 rounded-full z-20"
          id="start-btn"
          onClick={() => {
            setIsRegistered(true);
            setIsLoginPopupOpen(true);
          }}
        >
          {t("home-winning-button")}
        </button>

        <div className="flex flex-row justify-end items-end w-full z-10 xl:hidden">
          <img
            src={BallIcon}
            className="w-32 me-[-30px] mt-[-10px]"
            alt="ball"
          />
        </div>
      </div>
    </div>
  );
};

export default WinningPotential;
